// IMGS
import Wheat from "../../../imgs/Ingredients/wheat.png";
import Egg from "../../../imgs/Ingredients/egg.png";
import Milk from "../../../imgs/Ingredients/milk.png";
import Soy from "../../../imgs/Ingredients/soy.png";

import Donut from "../../../imgs/Ingredients/donut.png";
import Cookie from "../../../imgs/Ingredients/cookie.png";

function SmallProducts() {
  const smallProducts = [
    {
      Img: Cookie,
      tittle: "Cookie",
      p: "American cookie",
      price: "1.50",
      IngImgs: [Wheat, Egg, Milk, Soy],
    },
    {
      Img: Donut,
      tittle: "Donut",
      p: "Donut",
      price: "1.50",
      IngImgs: [Wheat, Egg, Milk, Soy],
    },
  ];

  const smallProductLoop = smallProducts.map((p) => {
    return (
      <div class="s-product">
        <img class="sp" src={p.Img} alt="" />
        <h4>{p.tittle}</h4>
        <p>
          <i>{p.p}</i>
        </p>
        <i id="bag" class="fa fa-shopping-bag bag"></i>
        <p class="c-price">{p.price}€</p>
        <div className="IngImgs">
          {p.IngImgs.map((i) => (
            <img src={i} alt=""></img>
          ))}
        </div>
      </div>
    );
  });

  return smallProductLoop;
}

export default SmallProducts;
