// COMPONENTS
import Food from "./Breakfast Components/Food";
import Pancakes from "./Breakfast Components/Pancakes";
import Bowls from "./Breakfast Components/Bowls";
import SmallProducts from "./Breakfast Components/small-products";

// CSS
import "../../css/Menu/breakfast.css";

function Breakfast() {
  return (
    <div className="breakfast-wrapper">
      <div className="breakfast">
        <div className="box1">
          <h2 className="tittle">
            <span className="first-letter">B</span>reakfast
          </h2>

          <Food />
        </div>

        <div className="product-division">
          <div className="ps1 pancakes">
            <h3 class="tittle">
              <span class="first-letter">P</span>ancakes
            </h3>

            <Pancakes />
          </div>
          <div className="ps1 bowls">
            <h3 class="tittle">
              <span class="first-letter">B</span>owls
            </h3>

            <Bowls />

            <div className="small-products">
              <SmallProducts />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Breakfast;
