// CSS
import "../../css/Home.css";

// IMGS
import board from "../../imgs/Home/Component 1.png";

function home() {
  return (
    <div id="home">
      <img src={board} alt="" />
    </div>
  );
}

export default home;
