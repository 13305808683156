// IMGS
import Wheat from "../../../imgs/Ingredients/wheat.png";
import Egg from "../../../imgs/Ingredients/egg.png";
import Milk from "../../../imgs/Ingredients/milk.png";
import Walnut from "../../../imgs/Ingredients/walnut.png";

function Pancakes() {
  const pancakes = [
    {
      tittle: "Original",
      IngImgs: [Wheat, Egg, Milk],
      description: "Maple Syrup",
    },
    {
      tittle: "Antioxidante",
      IngImgs: [Wheat, Egg, Milk],
      description: "Red berries",
    },
    {
      tittle: "Energético",
      IngImgs: [Wheat, Egg, Milk, Walnut],
      description: "Banana, dry fruits and honey",
    },
    {
      tittle: "Americano",
      IngImgs: [Wheat, Egg, Milk],
      description: "Bacon, egg, maple syrup and butter",
    },
    {
      tittle: "Fabio's",
      IngImgs: [Wheat, Egg, Milk],
      description: "Nutella,fruit of the season and whipped cream",
    },
  ];

  const pancakesLoop = () => {
    return pancakes.map((product) => {
      return (
        <div class="row col-2">
          <div class="food">
            <div class="title-food">
              <h3>{product.tittle}</h3>
              <div>
                {product.IngImgs.map((img) => (
                  <img src={img} alt="" />
                ))}
              </div>
            </div>
            <div class="price">
              <i id="bag" class="fa fa-shopping-bag bag"></i>
            </div>
          </div>
          <div class="food-description">
            <p>{product.description}</p>
          </div>
        </div>
      );
    });
  };

  return pancakesLoop();
}

export default Pancakes;
