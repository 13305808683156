// IMGS
import Wheat from "../../../imgs/Ingredients/wheat.png";
import Walnut from "../../../imgs/Ingredients/walnut.png";
import Peanut from "../../../imgs/Ingredients/peanut.png";

function Bowls() {
  const bowls = [
    {
      tittle: "Wake me up",
      IngImgs: [Wheat, Peanut, Walnut],
      description:
        "Yogurt, almond drink, banana, red fruits, strawberries, kiwi, dry fruits, coconut and chocolate",
    },
    {
      tittle: "Energy extra",
      IngImgs: [Walnut],
      description: "Açaí,banana, mango, pineapple,granola, nuts and coconut",
    },
  ];

  const bowlsLoop = () => {
    return bowls.map((product) => {
      return (
        <div class="row col-2">
          <div class="food">
            <div class="title-food">
              <h3>{product.tittle}</h3>
              <div>
                {product.IngImgs.map((img) => (
                  <img src={img} alt="" />
                ))}
              </div>
            </div>
            <div class="price">
              <i id="bag" class="fa fa-shopping-bag bag"></i>
            </div>
          </div>
          <div class="food-description">
            <p>{product.description}</p>
          </div>
        </div>
      );
    });
  };

  return bowlsLoop();
}

export default Bowls;
