// COMPONENTS
import {LazyLoadImage} from "react-lazy-load-image-component";

// CSS
import "../../css/About.css";

// IMGS
import video from "../../imgs/About/2021.05.12_Fábio's Roadstop_PromoVideo.mp4";

function About() {
  return (
    <div id="about">
      <div className="col text">
        <div className="global-headline">
          <h2 className="sub-headline">
            <span className="first-letter">D</span>iscover
          </h2>
          <h1 className="headline">our story</h1>
        </div>
        <p className="restaurant-description">
          Fabio’s Roadstop is a personal dream of mine, which came true. An
          American diner in conjunction with our own unique concept within which
          we value the quality of our produce and perform daily tests to improve
          the final results of our confections. All our attention is devoted to
          detail and therefore all our dishes are served in a careful and
          uniquely special way. We are a supportive team with the ambition to
          serve better and do better. We are in constant learning mode, testing,
          retrying, until we produce our products to the highest standard of
          quality. Our only goal is to offer something unique served with
          quality and great service. In our restaurant we want you to feel
          completely at home, to share happy moments whether they are with
          family or friends and to enjoy the best we have to offer, from the
          best drinks to a delicious meal.
        </p>
      </div>
      <div className="col">
        <video src={video} controls loading="lazy"></video>
      </div>
    </div>
  );
}

export default About;
