// COMPONENTS
import {LazyLoadImage} from "react-lazy-load-image-component";
import {Outlet, Link} from "react-router-dom";

// CSS
import "../../css/Menu.css";
import "react-lazy-load-image-component/src/effects/blur.css";

// IMGS
import breakfast from "../../imgs/Menu/breakfast.jpg";
import toast from "../../imgs/Menu/toast.jpg";
import salad from "../../imgs/Menu/salad.jpg";
import burger from "../../imgs/Menu/burger.jpg";
import drinks from "../../imgs/Menu/lemonades.jpg";
import hotdog from "../../imgs/Menu/hotdog.jpg";
import fingerfoods from "../../imgs/Menu/fingerfood.jpg";
import special from "../../imgs/Menu/special.jpg";
import veg from "../../imgs/Menu/veg.jpg";
import kids from "../../imgs/Menu/kids.jpg";
import desert from "../../imgs/Menu/desert.jpg";
import smoothies from "../../imgs/Menu/smoothies-milkshakes.jpg";

// LOADING IMAGES
import Loadingbreakfast from "../../imgs/Menu/loading/breakfast.jpg";
import Loadingtoast from "../../imgs/Menu/loading/toast.jpg";
import Loadingsalad from "../../imgs/Menu/loading/salad.jpg";
import Loadingburger from "../../imgs/Menu/loading/burger.jpg";
import Loadingdrinks from "../../imgs/Menu/loading/lemonades.jpg";
import Loadinghotdog from "../../imgs/Menu/loading/hotdog.jpg";
import Loadingfingerfoods from "../../imgs/Menu/loading/fingerfood.jpg";
import Loadingspecial from "../../imgs/Menu/loading/special.jpg";
import Loadingveg from "../../imgs/Menu/loading/veg.jpg";
import Loadingkids from "../../imgs/Menu/loading/kids.jpg";
import Loadingdesert from "../../imgs/Menu/loading/desert.jpg";
import Loadingsmoothies from "../../imgs/Menu/loading/smoothies-milkshakes.jpg";

function Menu() {
  return (
    <div id="menu" className="main-font">
      <h2 className="tittle">
        <span className="first-letter">M</span>enu
      </h2>
      <div className="small-container">
        <div className="div1 col-4">
          <Link to="Breakfast">
            <LazyLoadImage
              effect="blur"
              src={breakfast}
              placeholderSrc={Loadingbreakfast}
              alt=""
            />
            <div className="name">
              <h4>Breakfast</h4>
            </div>
          </Link>
        </div>
        <div className="div2 col-4">
          <a href="sandes.html">
            <LazyLoadImage
              effect="blur"
              src={toast}
              placeholderSrc={Loadingtoast}
              alt=""
            />
            <div className="name">
              <h4>Sandwiches & toasts</h4>
            </div>
          </a>
        </div>
        <div className="div3 col-4">
          <a href="wrap.html">
            <LazyLoadImage
              effect="blur"
              src={salad}
              placeholderSrc={Loadingsalad}
              alt=""
            />
            <div className="name">
              <h4>Wraps, Salads & omelettes</h4>
            </div>
          </a>
        </div>
        <div className="div4 col-4">
          <a href="hotdog.html">
            <LazyLoadImage
              effect="blur"
              src={hotdog}
              placeholderSrc={Loadinghotdog}
              alt=""
            />
            <div className="name">
              <h4>Hot dogs</h4>
            </div>
          </a>
        </div>
        <div className="div5 col-4">
          <a href="">
            <LazyLoadImage
              effect="blur"
              src={fingerfoods}
              placeholderSrc={Loadingfingerfoods}
              alt=""
            />
            <div className="name">
              <h4>Finger foods</h4>
            </div>
          </a>
        </div>
        <div className="div6 col-4">
          <a href="">
            <LazyLoadImage
              effect="blur"
              src={burger}
              placeholderSrc={Loadingburger}
              alt=""
            />
            <div className="name">
              <h4>Burgers</h4>
            </div>
          </a>
        </div>
        <div className="div7 col-4">
          <a href="">
            <LazyLoadImage
              effect="blur"
              src={special}
              placeholderSrc={Loadingspecial}
              alt=""
            />
            <div className="name">
              <h4>Fabio's Specials</h4>
            </div>
          </a>
        </div>
        <div className="div8 col-4">
          <a href="">
            <LazyLoadImage
              effect="blur"
              src={veg}
              placeholderSrc={Loadingveg}
              alt=""
            />
            <div className="name">
              <h4>Vegetarian & Vegan</h4>
            </div>
          </a>
        </div>
        <div className="div9 col-4">
          <a href="">
            <LazyLoadImage
              effect="blur"
              src={kids}
              placeholderSrc={Loadingkids}
              alt=""
            />
            <div className="name">
              <h4>Kid's Menu</h4>
            </div>
          </a>
        </div>
        <div className="div10 col-4">
          <a href="">
            <LazyLoadImage
              effect="blur"
              src={desert}
              placeholderSrc={Loadingdesert}
              alt=""
            />
            <div className="name">
              <h4>Desserts</h4>
            </div>
          </a>
        </div>
        <div className="div11 col-4">
          <a href="">
            <LazyLoadImage
              effect="blur"
              src={drinks}
              placeholderSrc={Loadingdrinks}
              alt=""
            />
            <div className="name">
              <h4>Drinks</h4>
            </div>
          </a>
        </div>
        <div className="div12 col-4">
          <a href="">
            <LazyLoadImage
              effect="blur"
              src={smoothies}
              placeholderSrc={Loadingsmoothies}
              alt=""
            />
            <div className="name">
              <h4>Smoothies & Milkshakes</h4>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Menu;
