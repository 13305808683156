import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";

import NavBar from "./components/views/NavBar";
import Body from "./components/views/Body";
import Footer from "./components/views/Footer";
import Login from "./components/views/Login";

// MENU COMPONENTS
import Breakfast from "./components/views/Menu/breakfast";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route className="link active" path="/" element={<NavBar />}>
            <Route index element={<Body />} />
            <Route path="Login" element={<Login />} />
            <Route path="Breakfast" element={<Breakfast />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
