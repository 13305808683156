// CSS
import "../css/Login.css";

function Login() {
  return (
    <div className="login-wrapper">
      <div className="wrapper">
        <div className="form-box login">
          <h2>Login</h2>
          <form action="">
            <div className="input-box">
              <span className="icon material-symbols-rounded">mail</span>
              <input type="email" name="" id="" required />
              <label htmlFor="email">Email</label>
            </div>
            <div className="input-box">
              <span className="icon material-symbols-rounded">lock</span>
              <input type="password" name="" id="" required />
              <label htmlFor="email">Password</label>
            </div>
            <div className="remember-forgot">
              <label>
                <input type="checkbox" name="" id="" />
                Remember me
              </label>
              <a href="">Forgot password?</a>
            </div>
            <button type="submit" className="btn">
              Login
            </button>
            <div className="login-register">
              <p>
                Don't have an account?
                <a href="" className="register-link">
                  Register
                </a>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
