// COMPONENTS
import {Outlet, Link} from "react-router-dom";

// CSS
import "../css/NavBar.css";

// IMGS
import Logo from "../imgs/Logo.png";

function Navbar() {
  const NavClick = function (e) {
    const navLinks = document.querySelectorAll(".link");
    const link = e.target.closest(".link");

    // Guard clause
    if (!link) return;

    // Remove active class from all nav links
    navLinks.forEach((l) => l.classList.remove("active"));

    // Add active class to link that has been clicked
    link.classList.add("active");
  };

  const liActive = function (e) {
    const href = window.location.href.split("/")[3];
    const homeLi = document.querySelector(".home");
    const aboutLi = document.querySelector(".about");
    const menuLi = document.querySelector(".menu");

    if (href === "#home" || href === "") {
      homeLi.classList.add("active");
    }

    if (href === "#about") {
      aboutLi.classList.add("active");
    }

    if (href === "#menu") {
      menuLi.classList.add("active");
    }
  };

  const burger = function (e) {
    const burger = document.querySelector(".fa-hamburger");
    const listLi = document.querySelector(".list-li");
    const listLink = document.querySelectorAll(".list-link");

    // Guard clause
    if (!burger) return;

    burger.classList.toggle("fa-close");
    listLi.classList.toggle("show");

    listLink.forEach((i) => {
      i.classList.toggle("show");
    });

    listLink.forEach((i) => {
      i.addEventListener("click", function () {
        burger.classList.remove("fa-close");
        listLi.classList.remove("show");

        listLink.forEach((i) => {
          i.classList.remove("show");
        });
      });
    });
  };

  return (
    <>
      <header onLoad={liActive}>
        <a href="/" className="logo">
          <img src={Logo} alt="Logo" />
        </a>

        <nav>
          <ul onClick={NavClick} className="nav-links">
            <div className="list-li">
              <li className="list-link">
                <a className={"link home"} href="/#home">
                  HOME
                </a>
              </li>

              <li className="list-link">
                <a className={"link about"} href="/#about">
                  ABOUT
                </a>
              </li>

              <li className="list-link">
                <a className={"link menu"} href="/#menu">
                  MENU
                </a>
              </li>

              <li className="list-link">
                <Link
                  to="Login"
                  className={
                    window.location.href.split("/")[3] === "Login"
                      ? "link active"
                      : "link"
                  }
                >
                  LOGIN
                </Link>
              </li>

              <li className="list-link">
                <Link
                  to="Register"
                  className={
                    window.location.href.split("/")[3] === "Register"
                      ? "link active"
                      : "link"
                  }
                >
                  SIGNUP
                </Link>
              </li>
            </div>

            <li className="cart">
              <i onClick={burger} id="menu-bar" className="fa fa-hamburger"></i>
              <Link className="shopping-bag" href="#">
                <i className="fa fa-shopping-bag"></i>
              </Link>
            </li>
          </ul>
        </nav>
      </header>

      <Outlet />
    </>
  );
}

export default Navbar;
