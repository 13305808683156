import "../css/Footer.css";

function Footer() {
  return (
    <footer>
      <div className="box-container">
        <div className="box box1">
          <h3>Contact info</h3>
          <a href="https://g.page/Fabiosroadstop?share" target="_blank">
            <i className="contact-i fa fa-map-marker"></i>Rua Dr. Manuel de
            Arriaga Edf. Mar Salgado, Lote 1 Loja E, 8365-140, Armação de Pêra
          </a>
          <a id="inline" href="mailto:fabio@fabiosroadstop.com" target="_blank">
            <i className="contact-i fa fa-envelope"></i>fabio@fabiosroadstop.com
          </a>
          <a href="tel://+351282317025">
            <i className="contact-i fa fa-phone"></i>(+351) 282 317 025
          </a>
        </div>

        <div className="box box2">
          <h3>Opening hours</h3>
          <p>Tuesday to Saturday from 8:00 to 22:30</p>
        </div>

        <div className="box box3">
          <h3>Follow us</h3>
          <a href="https://www.instagram.com/fabiosroadstop/" target="_blank">
            <i className="fab fa-instagram"></i>Instagram
          </a>
          <a href="https://www.facebook.com/fabiosroadstop.fb/" target="_blank">
            <i className="fab fa-facebook"></i>Facebook
          </a>
          <a
            href="https://www.tripadvisor.pt/Restaurant_Review-g189113-d17755337-Reviews-Fabio_s_RoadStop-Armacao_de_Pera_Faro_District_Algarve.html"
            target="_blank"
          >
            <i className="fa fa-tripadvisor"></i>TripAdvsor
          </a>
        </div>
      </div>
      <hr></hr>
      <p className="credit">Fabio's Roadstop - All rights reserved</p>
    </footer>
  );
}

export default Footer;
