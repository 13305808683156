// IMGS
import Wheat from "../../../imgs/Ingredients/wheat.png";
import Egg from "../../../imgs/Ingredients/egg.png";
import Milk from "../../../imgs/Ingredients/milk.png";
import Fish from "../../../imgs/Ingredients/fish.png";
import Sesame from "../../../imgs/Ingredients/sesame.png";

function Food() {
  const foodMenu = [
    {
      tittle: "Inglês",
      IngImgs: [Wheat, Egg],
      description:
        "Double bacon, egg, sausage and toast, baked beans, fresh mushrooms and tomato",
      price: "8.50",
    },
    {
      tittle: "Super Inglês",
      IngImgs: [Wheat, Egg],
      description:
        "Double bacon, egg, sausage and toast, baked beans, fresh mushrooms and tomato",
      price: "12.50",
    },
    {
      tittle: "Continental",
      IngImgs: [Wheat, Milk, Egg],
      description: "Croissant, bread, ham, cheese, butter, jam and yogurt",
      price: "7.00",
    },
    {
      tittle: "Fabio's",
      IngImgs: [Wheat, Milk, Egg, Fish, Sesame],
      description:
        "Cereal bread with philadelphia cheese, smoked salmon, scrambled egg and yogurt",
      price: "8.50",
    },
  ];

  const foodLoop = () => {
    return foodMenu.map((food) => {
      return (
        <div className="row">
          <div className="food">
            <div className="title-food">
              <h3>{food.tittle}</h3>
              <div>
                {food.IngImgs.map((IngImg) => (
                  <img src={IngImg} alt="" />
                ))}
              </div>
            </div>
            <div className="price">
              <p className="price-tag">{food.price}€</p>
              <i id="bag" className="fa fa-shopping-bag"></i>
            </div>
          </div>
          <div>
            <p className="food-dp">{food.description}</p>
          </div>
        </div>
      );
    });
  };

  return foodLoop();
}

export default Food;
