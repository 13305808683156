// COMPONENTS
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./Body/home";
import About from "./Body/About";
import Menu from "./Body/Menu";
import Fade from "react-reveal/Fade";

function Body() {
  return (
    <>
      <Home />
      <Fade>
        <About />
      </Fade>
      <Fade>
        <Routes>
          <Route path="/" element={<Menu />}></Route>
        </Routes>
      </Fade>
    </>
  );
}

export default Body;
